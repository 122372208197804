/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Content, { HTMLContent } from "./Content"


export const DomaineTemplate = ({
  title,
  numeroDomaine,
  placement,
  background,
  svgFile,
  tags,
  content,
  contentComponent,
  showTag,
  closeTag
}) => {
  const PostContent = contentComponent || Content

  return (
    <>
      {placement === 'gauche' ? (
        <>
          <div className="columns is-vcentered is-centered has-text-centered column is-4-desktop" style={{position: 'relative', background: '#373e5a'}}>
          {/*background && background.childImageSharp ? (
            <Img
              fixed={background.childImageSharp.fixed}
              style={{width: '100%', height:'100%',position: 'absolute', top: '0', left: '0'}}
            />
          ) : null*/}
          { !svgFile.childImageSharp && svgFile.extension === 'svg' ? (
            <img className="domaines-svg" style={{width:'200px',height:'200px',position: 'relative',margin: '2rem'}} src={svgFile.publicURL} alt="" />
          ) : null }
          </div>
          <div className="column is-8-desktop is-full-touch domaines-right" style={{ paddingTop: 1, marginTop: 0 }}>
            <h2 className="is-size-4-touch">{title}</h2>
            <PostContent content={content} className={"has-text-justified"} />
            <div className="tags">
            {tags && Object.values(tags).map((tag, index) => {
              if (tag.description) 
                return <span className="tag tag-button button is-text" onClick={() => showTag(numeroDomaine+'-'+index)}>{tag.libelle}</span>
              else
                return <span className="tag">{tag.libelle}</span>
              }
            )}
            </div>
          </div>
          {tags && Object.values(tags).map((tag, index) => {
            if (tag.description) 
              return(
                <div id={numeroDomaine+'-'+index} className="tag-description column is-12" style={{display:'none'}}>
                  <button className="button is-danger is-inverted" style={{float:'right'}}
                    onClick={() => closeTag(numeroDomaine+'-'+index)}>
                    Fermer &times;
                  </button>
                  <h3 className="title">{tag.libelle}</h3>
                  <p>{tag.description}</p>

                </div>
              )
          })}
        </>
      ):(
        <>
          <div className="column is-8-desktop is-full-touch domaines-left">
            <h2 className="is-size-4-touch">{title}</h2>
            <PostContent content={content} className={"has-text-justified"} />
            <div className="tags">
            {tags && Object.values(tags).map((tag, index) => {
              if (tag.description) 
                return <span className="tag tag-button" onClick={() => showTag(numeroDomaine+'-'+index)}>{tag.libelle}</span>
              else
                return <span className="tag">{tag.libelle}</span>
              }
            )}
            </div>
          </div>
          <div className="columns is-vcentered is-centered has-text-centered column is-4-desktop is-12-touch domaines-right-image">
            {/*background && background.childImageSharp ? (
              <Img
                fluid={background.childImageSharp.fluid}
              />
            ) : null*/}
            { !svgFile.childImageSharp && svgFile.extension === 'svg' ? (
              <img className="domaines-svg" style={{width:'200px',height:'200px', margin: '2rem'}} src={svgFile.publicURL} alt="" />
            ) : null }
          </div>
          {tags && Object.values(tags).map((tag, index) => {
            if (tag.description) 
              return(
                <div id={numeroDomaine+'-'+index} className="tag-description column is-12" style={{display:'none'}}>
                  <button className="button is-danger is-inverted" style={{float:'right'}}
                    onClick={() => closeTag(numeroDomaine+'-'+index)}>
                    Fermer &times;
                  </button>
                  <h3 className="title">{tag.libelle}</h3>
                  <p>{tag.description}</p>
                  
                </div>
              )
          })}
        </>
      )}
    </>
  )
}

class Domaines extends React.Component {
  constructor(props) {
    super(props)
  }

  showTag(idTag) {
    const displayTag = document.getElementById(idTag).style.display;
    if (displayTag === "none")
      document.getElementById(idTag).style.display = "";
    else 
      document.getElementById(idTag).style.display = "none";
  }

  closeTag(idTag) {
    document.getElementById(idTag).style.display = "none"
  }

  render() {
    const { data } = this.props
    const { edges: domaines } = data.allMarkdownRemark

    return (
      <section>
        <div className="columns is-gapless is-multiline domaines-details">
          {domaines &&
            domaines.map(({ node: post }) => (
              <DomaineTemplate
                title={post.frontmatter.title}
                numeroDomaine={post.frontmatter.numeroDomaine}
                placement={post.frontmatter.placement}
                background={post.frontmatter.background}
                svgFile={post.frontmatter.svgFile}
                tags={post.frontmatter.tags}
                content={post.html}
                contentComponent={HTMLContent}
                showTag = {(idTag) => this.showTag(idTag) }
                closeTag = {(idTag) => this.closeTag(idTag) }
              />
            ))}
        </div>
      </section>
    )
  }
}

Domaines.propTypes = {
  data: PropTypes.object
}

export default Domaines
