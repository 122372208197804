import React from "react"
import PropTypes from "prop-types"
import { graphql } from 'gatsby'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Domaines from "../../components/domaines"

import { ReactComponent as Ampoule } from "../../img/Page03/AmpouleBandeau.svg"

const DomainesPage = ({ data }) => {
  return (
    <Layout langKey="en">
      <SEO
          title="Our expertise in software engineering"
          description="RFID, NFC traceability - Internet Of Things - Database management - Applications and websites - Android applications - Operating systems"
          keywords={[`RFID`, `NFC`, `IOT`, `Server`, `Database`, `Website`, `Application`, `Software`, `Android`]}
          jsonLd={[
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Expertise",
                  item: "https://www.aprogsys.com/en/domaines/",
                },
              ],
            },
          ]}
      />
      <section className="hero domaines-banner">
        <div className="hero-body container is-fluid">
          <div className="columns is-centered">
            <div className="column is-6">
              <div className="hero-title domaines-banner-content">
                <h1 className="is-size-3-touch domaines-banner-title"><span>Our <br className="is-hidden-desktop"/>expertise</span></h1>
                <div className="subtitle domaines-banner-title is-size-4-touch has-text-primary" style={{ fontSize: '2.5rem', marginTop: '0.5rem', lineHeight: '4.2rem' }}>
                  <span>
                    Discover the diversity of our skills in <strong>software engineering</strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="column is-2 has-text-centered">
              <Ampoule style={{height:300}} />
            </div>
          </div>
        </div>
      </section>
      <Domaines data={data} />
    </Layout>
  )
}

DomainesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default DomainesPage

export const DomainesPageQuery = graphql`
  query DomainesEnQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___numeroDomaine] }
      filter: { frontmatter: { templateKey: { eq: "domaines" }, langKey: { eq: "en" } } }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            numeroDomaine
            placement
            background { 
              childImageSharp {
                fixed(width: 1000, quality: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            svgFile { 
              childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            tags {
              libelle,
              description
            }
          }
        }
      }
    }
  }`
